import cloneDeep from 'lodash/cloneDeep';
import plural from 'plural-ru';

import type {Currency} from 'client/common/types';

import {getSortedTranslations} from 'assets/i18n';
import I18N from 'assets/i18n/brandlift-project';

const i18n = I18N.common.utils.format;
const i18nCountry = I18N.common.countryModal;

const LOCALE = 'ru-RU';

export function formatNumber(
    number: string | number,
    settings: {rounding: 'down' | 'nearest' | false; fractionDigits?: number} = {
        rounding: 'down',
        fractionDigits: 0
    }
): string {
    const {rounding, fractionDigits} = settings;

    let intNumber: number;

    if (rounding) {
        intNumber = rounding === 'down' ? Math.floor(Number(number)) : Math.round(Number(number));
    } else {
        intNumber = Number(number);
    }

    if (Number.isNaN(intNumber)) {
        return '0';
    }

    return intNumber.toLocaleString(LOCALE, {
        minimumFractionDigits: 0,
        maximumFractionDigits: fractionDigits
    });
}

export function formatPercent(ratio: number, fractionDigits = 0, postfix = '%'): string {
    if (Number.isNaN(ratio)) {
        return '0';
    }

    // не используем style:'percent' из-за лишнего пробела
    return `${(ratio * 100).toLocaleString(LOCALE, {
        minimumFractionDigits: 0,
        maximumFractionDigits: fractionDigits
    })}${postfix}`;
}

export function formatDate(dateString: string, short = false): string {
    const date = new Date(dateString);
    const now = new Date();

    const yearFormat = short ? '2-digit' : 'numeric';
    const monthFormat = short ? 'numeric' : 'long';

    return date.toLocaleString(LOCALE, {
        year: date.getFullYear() === now.getFullYear() ? undefined : yearFormat,
        month: monthFormat,
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    });
}

export const formatDateToDayMonthYear = (date: Date, short = false) => {
    const rawDay = date.getDate();
    const rawMonth = date.getMonth() + 1;
    const day = rawDay >= 10 ? rawDay : `0${rawDay}`;
    const month = rawMonth >= 10 ? rawMonth : `0${rawMonth}`;
    const year = date.getFullYear().toString().slice(short ? 2 : 0);

    return `${day}.${month}.${year}`;
};

export function currencySymbolFromCurrency(currency: Currency) {
    const symbol =
        {
            RUB: i18nCountry.currency.RUB,
            KZT: i18nCountry.currency.KZT,
            BYN: i18nCountry.currency.BYN,
            USD: i18nCountry.currency.USD,
            CHF: i18nCountry.currency.CHF,
            EUR: i18nCountry.currency.EUR,
            UZS: i18nCountry.currency.UZS
        }[currency] || currency;

    return symbol;
}

export const formatPriceValue = ({
    value = 0,
    currency
}: {
    value: number | undefined;
    currency: Currency;
}) => {
    const valueNumber = parseFloat(value.toFixed(2)).toLocaleString(LOCALE);
    const currencySymbol = currencySymbolFromCurrency(currency);

    return `${valueNumber} ${currencySymbol}`;
};

export function formatFileSize(sizeInBytes: number): string {
    return `${Math.floor(sizeInBytes / 1024)} ${i18n.fileSize.kb}`;
}

export function formatFileName(fileName: string): string {
    const MAX_LENGTH = 30;
    const CHARS_AT_END = 8;

    if (fileName.length > MAX_LENGTH) {
        return `${fileName.substring(0, MAX_LENGTH - CHARS_AT_END)}...${fileName.substring(
            fileName.length - CHARS_AT_END
        )}`;
    }

    return fileName;
}

export function getQuestionCountLabel(count: number): string {
    return plural(count, ...(getSortedTranslations(i18n.question) as [string, string, string]));
}

export function getOptionCountLabel(count: number): string {
    return plural(count, ...(getSortedTranslations(i18n.option) as [string, string, string]));
}

export function getPointCountLabel(count: number): string {
    return plural(count, ...(getSortedTranslations(i18n.point) as [string, string, string]));
}

export const formatHHMMSS = (timeInSec: number) => {
    const timeString = new Date(1000 * timeInSec).toISOString().substr(11, 8);

    if (timeString.startsWith('00:')) {
        return timeString.slice(3);
    }

    return timeString;
};

export const formatLocaleTime = (timeInSec: number) => {
    const hours = Math.floor(timeInSec / 3600);
    const minutes = Math.floor((timeInSec % 3600) / 60);
    const seconds = Math.floor(timeInSec % 60);

    const result = [];

    if (hours > 0) result.push(`${hours} ${i18n.time.hour}`);
    if (minutes > 0) result.push(`${minutes} ${i18n.time.min}`);
    if (seconds > 0) result.push(`${seconds} ${i18n.time.sec}`);

    return result.join(' ') || `0 ${i18n.time.sec}`;
};

export function formatAgeIntervals(intervals: string[], separator = '; '): string {
    const sorted = cloneDeep(intervals).sort();

    let [previous]: string[] = sorted;

    const merged = [];

    for (const current of sorted.slice(1)) {
        const previousInterval = {
            from: Number(previous.slice(0, 2)),
            to: Number(previous.slice(-2))
        };
        const currentInterval = {
            from: Number(current.slice(0, 2)),
            to: Number(current.slice(-2))
        };

        if (currentInterval.from === previousInterval.to + 1) {
            previous = currentInterval.to
                ? `${previousInterval.from}-${currentInterval.to}`
                : `${previousInterval.from}+`;
        } else {
            merged.push(previous);
            previous = current;
        }
    }

    merged.push(previous);

    return merged.join(separator);
}
